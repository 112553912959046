.table-list {
  // padding: 20px;
  background-color: white;
  border-radius: 20px;
  // margin:70px 30px 30px 260px;
}

.table {
  .thead-primary {
    th {
      color: white;
      background-color: #4f46e5;
      border-color: #4f46e5;
    }
  }

  .thead-success {
    th {
      color: white;
      background-color: #4f46e5;
      border-color: #4f46e5;
    }
  }

  .team-info {
    li {
      transition: all 0.25s ease;
      -webkit-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;
      -moz-transition: all 0.25s ease;

      & + li {
        margin-left: -10px;
      }

      img {
        height: 30px;
        width: 30px;
        border-radius: 30px;
        border: 2px solid white;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
        object-fit: cover;
      }

      &:hover {
        // -webkit-transform: translateY(-4px) scale(1.02);
        // -moz-transform: translateY(-4px) scale(1.02);
        // -ms-transform: translateY(-4px) scale(1.02);
        // -o-transform: translateY(-4px) scale(1.02);
        // transform: translateY(-4px) scale(1.02);
        // z-index: 999;

            img {
                height: 30px;
                width: 30px;
                border-radius: 30px;
                border: 2px solid white;
                box-shadow: 0px 20px 50px 0px rgba(61.999999999999815, 54.99999999999995, 177.99999999999994, 0.11);
                object-fit: cover;
            }

            &:hover {
                // -webkit-transform: translateY(-4px) scale(1.02);
                // -moz-transform: translateY(-4px) scale(1.02);
                // -ms-transform: translateY(-4px) scale(1.02);
                // -o-transform: translateY(-4px) scale(1.02);
                // transform: translateY(-4px) scale(1.02);
                // z-index: 999;

                // img {
                //     -webkit-box-shadow: 0 14px 24px rgba(62, 57, 107, .2);
                //     box-shadow: 0 14px 24px rgba(62, 57, 107, .2);
                // }
            }
        }

        // img {
        //     -webkit-box-shadow: 0 14px 24px rgba(62, 57, 107, .2);
        //     box-shadow: 0 14px 24px rgba(62, 57, 107, .2);
        // }
      }

    }
  }

  .thead-light {
    background-color: #f6f6f9;
    color: #9A96FC;
  }

  tbody {
    border-radius: 10px !important;

    tr,
    th {
      td {
        vertical-align: middle;
        white-space: nowrap;
      }
    }
  }

