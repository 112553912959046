@font-face {
  font-family: "Cerebri Sans,sans-serif";
  src: url("./assets/fonts/cerebrisans-light.eot");
  src: local("Cerebri-sans Light"),
    url("./assets/fonts/cerebrisans-light.woff") format("woff");
  font-weight: 100 !important;
}
/* @font-face {
  font-family: "Urbanist";
  src: url("./assets/fonts/Urbanist-Regular.ttf");
  src: local("Urbanist Light"),
    url("./assets/fonts/Urbanist-Regular.ttf") format("woff");
  font-weight: 100 !important;
} */
body {
  margin: 0;
  font-family: "Cerebri Sans,sans-serif" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
