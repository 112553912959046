.parent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center !important ;
}
.center {
  display: flex;
  justify-content: center;
}
