// .p-component {
//   button {
//     border: none;
//     background-color: transparent;
//   }
// }

@media (max-width: 687px) {
  .p-component {
    .p-icon {
      width: 9px !important;
      height: 9px !important;
     
      
    }
  }
  .small-screen {
    font-size: 10px !important;
    display: flex;
    flex-wrap: nowrap;
  }
}
