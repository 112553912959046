body[data-layout-mode="dark"] {
  background-color: lighten($gray-dark-200, 2%);
  color: $gray-dark-500;

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $gray-dark-600;
  }

  .p-tabview-panels,
  .p-tabview-nav-link,
  .p-tabview-nav,
  .table-responsive,
  .bot-hover,
  .p-paginator,
  .admin-payment-card,
  .admin-user-card,
  .p-dropdown {
    background-color: $gray-dark-200 !important;
  }

  .accordion {
    background-color: $gray-dark-200 !important;
  }

  .chat-conversation .right .conversation-list .ctext-wrap .ctext-wrap-content {
    background-color: #4f46e5 !important;
  }

  .thead-light,
  .p-dropdown-trigger,
  .p-dropdown-items {
    background-color: $gray-dark-300 !important;
  }

  .p-dropdown {
    border: none;
  }

  .hover-tr-effect {
    cursor: pointer;

    &:hover {
      background-color: $gray-dark-300 !important;
    }
  }

  .chat-history-list {
    &:hover {
      background-color: white;
    }
  }

  .p-inputtext,.form-control {
    background-color:    $gray-dark-300 !important;
    border: none !important;
  }

  .p-datepicker:not(.p-datepicker-inline),
  .p-datepicker-header,
  .p-datepicker-month,
  .p-datepicker-year {
    background-color: $gray-dark-300 !important;
    border: none !important;
    color: #fafbfc !important;
  }
}

.bot-click:hover {
  cursor: pointer !important;
}

.p-tabview .p-tabview-nav {
  .p-tabview-ink-bar {
    background-color: $sidebar-menu-item-active-color !important;
  }

  li.p-highlight .p-tabview-nav-link {
    border-color: $sidebar-menu-item-active-color !important;
    color: $sidebar-menu-item-active-color !important;
  }
}

.css-187mznn-MuiSlider-root {
  color: $sidebar-menu-item-active-color !important;
}

.admin-user-card {
  // background-color: white;
  height: 83vh !important;
  // overflow-y: scroll;
  overflow-x: hidden;
  padding: 12px !important;
}

.admin-payment-card {
  // background-color: white;
  height: 60vh !important;
  // overflow-y: scroll;
  overflow-x: hidden;
  padding: 12px !important;
}

.payment-screen {
  @media (max-width: 767px) {
    height: 100vh !important;
    // overflow-y: scroll;
    overflow-x: hidden;
    // overflow-y: hidden;
    // padding: 12px !important;
  }
}

.scroll-size {
  // background-color: white;
  height: 40vh !important;
  // overflow-y: scroll;
  overflow-x: hidden;
  padding: 12px !important;
  margin-top: 20px;
}

.css-187mznn-MuiSlider-root {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.p-tabview-panels {
  padding: 0px !important;
}

.p-inputtext {
  border: 1px solid #e6ebf5 !important;
  border-radius: 0.25rem !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
}

.p-tabview-nav-link:focus {
  box-shadow: none !important;
}

.bot-hover:hover {
  // border: 1px #4f46e5;
  // border-style:inset ;
  // box-shadow: #4f46e5 0px 8px 24px;
  box-shadow: #4f46e5 0px 2px 8px 0px;
  transition: 0.5s;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.modal-size .modal-content {
  height: 100vh !important;
}

.accordion-item {
  border-top: none;
  border-right: none;
  border-left: none;
}

.custom-div {
  padding: 20px;
  margin: 50px;

  @media (max-width: 767px) {
    padding: 0;
    // padding-top: 60px !important;
    margin: 0;
    margin-top: 80px !important;
  }
}

.custom-div-managedata {
  margin: 40px 50px 0 50px;

  @media (max-width: 767px) {
    padding: 0 !important;
    margin: 0 !important;
    margin-top: 80px !important;
  }

  .p-tabview-nav-link {
    padding: 10px !important;
  }

  .tap-font-size {
    @media (max-width: 767px) {
      font-size: 14px !important;
      padding: 0 !important;
    }
  }
}

.card-icon {
  background-color: $blue;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.icon-card .bx {
  font-size: 30px;
}

.display {
  display: none !important;

  @media (max-width: 767px) {
    display: block !important;
  }
}

.hover-tr-effect {
  cursor: pointer;

  &:hover {
    background-color: #00000006;
  }

}

.p-component {
  font-family: "Cerebri Sans,sans-serif" !important;
}
.p-tabview .p-tabview-nav li
{
  margin-right: 20px !important;
}
.form-control{
  border: 1px solid #e6ebf5 !important;
}